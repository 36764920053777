import Footer from "../Footer/Footer";
import SigninSignupBackground from "../SigninSignupBackground/SigninSignupBackground";
import SignInForm from "./SigninForm";

function Signin() {
    return (
      <div className="signin">
        <SigninSignupBackground />
        <div className="signin-form">
          <SignInForm/>
          <Footer/>
        </div>
      </div>
    );
};

export default Signin;