import axios from "axios";
import { customerSitesURL, sitesURL } from "../../constants/apiUrl";

export const getSitesData = async (callbackFn, customerId) => {
  try {
    const response = await axios.request({
        baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
        url: customerId ? `${customerSitesURL}/${customerId}` : sitesURL,
        method: 'get',
    });
    let data = response?.data || response;
    data = data?.map(row => ({
      ...row,
      customeR_ID: customerId || row.customeR_ID,
      'locationsCount': row?.locations?.length || 0
    }));
    callbackFn(data);  
  }
  catch(e) {
    console.log('Something went wrong, please try again later.')
  }
};