import axios from "axios";
import { customerLocationsURL, locationsURL } from "../../constants/apiUrl";
import { getCustomerData } from "../Customers/AddCustomer/customerAPI";

export const getLocationsData = async (callbackFn, customerId) => {
  try {
    const response = await axios.request({
        baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
        url: customerId ? `${customerLocationsURL}/${customerId}` : locationsURL,
        method: 'get',
    });
    callbackFn(response?.data || response);      
  }
  catch(e) {
    console.log('Something went wrong, please try again later.')
  }
};

export const getCustomerLocationsData = (customerID, callbackFn) => {
  getCustomerData(customerID, selectedCustomerData => {
    let locationsArr = [];
    selectedCustomerData?.sites?.forEach(({sitE_ID, locations}) => {
      locations = locations.map(location => ({...location, sitE_ID}));
      locationsArr = [
        ...locationsArr,
        ...locations
      ]
    });
    callbackFn(locationsArr, selectedCustomerData);
  });
}