import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { toast } from 'react-toastify';

export const exportToPDF = (TableTitle, headers, exportData) => {
  try {
    const doc = jsPDF('l', 'mm','a4');// new jsPDF();

    //Html way
    //autoTable(doc, { html: '#table-id' })
    // Sometimes you might have to call the default function on the export (for example in Deno)
    //autoTable.default(doc, { html: '#my-table' })

    // Javascript way:
    autoTable(doc, {
      head: [[...headers]],
      body: [
        ...exportData
      ],
    });

    doc.save(`${TableTitle}.pdf`);
    toast('Data exported successfully.')
  }
  catch(e) {
    toast('Something went wrong, please try again later.');
  }
};

export const exportToCSVExls = (TableTitle, exportData, exportFileType) => {
  try {
    exportData = exportData.join('\n');
    const CSVFile = new Blob([exportData], { type: `text/${exportFileType}` });
    let tempExportFileLink = document.createElement('a');
    tempExportFileLink.download = `${TableTitle}.${exportFileType}`;
    let url = window.URL.createObjectURL(CSVFile);
    tempExportFileLink.href = url;
    tempExportFileLink.style.display = "none";
    document.body.appendChild(tempExportFileLink);
    tempExportFileLink.click();
    document.body.removeChild(tempExportFileLink);
    toast('Data exported successfully.')
  }
  catch(e) {
    toast('Something went wrong, please try again later.');
  }
}