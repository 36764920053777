import axios from "axios";
import { getCustomerDropdownDataURL } from "../constants/apiUrl";

export const getCustomersDropdownData = async (callbackFn) => {
  try {
    const response = await axios.request({
        baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
        url: getCustomerDropdownDataURL,
        method: 'get',
    });
    callbackFn(response?.data);
  }
  catch(e) {
    console.log('Something went wrong, please try again later.')
  }
};