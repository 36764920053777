import { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { countriesURL } from '../../constants/apiUrl';
import { countriesAdded } from '../../appSlices';

function CountryDropdown({
  isCountryPhoneCodeDropdown,
  onChange,
  name,
  defaultValue=13,
  placeholder="Select Country",
  displayDropdown=false
}) {
  const dispatch = useDispatch();
  const countries = useSelector(state => state.appData.countries);

  const getCountries = async () => {
    try {
      const response = await axios.request({
          baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
          url: countriesURL,
          method: 'get',
      });
      dispatch(countriesAdded(response?.data));
    }
    catch(e) {
      console.log('Something went wrong with country dropdown, please try again later.')
    }
  };

  useEffect(() => {
    (!countries || !countries.length) && getCountries();
  }, []);

  return displayDropdown ? (
    <select
      value={defaultValue}
      name={name}
      className='country-dropdown'
      onChange={onChange}>
        <option value="">{placeholder}</option>
      {
        isCountryPhoneCodeDropdown ? (
          countries.map(country => (
            <option value={country.countrY_ID}>+{country.countrY_ID}</option>
          ))
        ) : (
          countries.map(country => (
            <option value={country.countrY_ID}>{country.nicename}</option>
          ))
        )
      }
    </select>
  ) : <></>;
};

export default CountryDropdown;