import React from 'react';
import { Link } from 'react-router-dom';
import AppLogo from '../../atoms/AppLogo';
import Breadcrumb from '../../atoms/Breadcrumb';
import ProfileDropdown from '../../atoms/ProfileDropdown';
import './header.scss';

function Header() {
  return (
    <div className='vrfid-header'>
      <div className='vrfid-header-logo'>
        <Link to="/home">
          <AppLogo/>
        </Link>
      </div>
      <div className='vrfid-header-container'>
        <div className='vrfid-header-row'>
          <Breadcrumb/>
          <ProfileDropdown/>
        </div>
      </div>
      
    </div>
  );
}

export default Header;