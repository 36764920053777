function ToggleSwitch({
    onChange,
    name,
    selected
}) {
  return (
    <label class="switch">
      <input type="checkbox" value="1" checked={selected} name={name} onChange={onChange}/>
      <span class="slider round"></span>
    </label>
  )
}

export default ToggleSwitch;