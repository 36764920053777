import { createSlice } from '@reduxjs/toolkit'
import TableConfig from './tableConfig.json';


const { rows } = TableConfig;

const passSessions = createSlice({
  name: 'passSessions',
  initialState: {
    data: [...rows],
    updateGridData: 1,
    selectedDataRow: {}
  },
  reducers: {
    addGridData(state, action) {
      state.data = [...action.payload];
    },
    updateGridData(state, action) {
      state.updateGridData = Math.floor(Math.random()*10000);
    },
    gridRowSelected(state, action) {
      state.selectedDataRow = {...action.payload}
    },
  },
})

export const { addGridData, updateGridData, gridRowSelected } = passSessions.actions
export default passSessions.reducer;