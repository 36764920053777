import React, { createContext, useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Sidenav from '../../atoms/Sidenav';
import WarningMessage from '../../atoms/WarningMessage';
import AdminMenu from './MenuJSON/adminMenu.json';
import ApprovedCustomerMenu from './MenuJSON/customerMenu.json';
import PendingCustomerMenu from './MenuJSON/newCustomerMenu.json';
import { PendingCustomerMessage } from '../../utilities/constants';
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import { getUserData } from '../../utilities/app';

export const BreadcrumbContext = createContext([
  {
    "linkText": "Home",
    "path": "/home"
  }
]);

function Layout() {
  const navigate = useNavigate();
  const [currentBreadcrumb, setCurrentBreadcrumb] = useState(null);
  const { userType, status } = getUserData();
  useEffect(() => {
    !userType && navigate('/signin');
  }, [userType]);

  const getMenuJson = () => {
    return userType === 'customer' ? (
        status == 1 ? ApprovedCustomerMenu : PendingCustomerMenu
    ) : AdminMenu;
  }

  return (
    <BreadcrumbContext.Provider value={{currentBreadcrumb, setCurrentBreadcrumb}}>
      <div className='layout'>
        <div className='layout-header'>
          <Header/>
        </div>
        <div className='layout-page'>
          <div className='layout-sidenav'>
            <Sidenav
              menus = { getMenuJson() }
            />
          </div>
          <div className='layout-content'>
            {!status ? <WarningMessage msg={PendingCustomerMessage}/> : null}
            <Outlet />
          </div>
        </div>
      </div>
    </BreadcrumbContext.Provider>
  );
}

export default Layout;