import { useContext, useEffect } from 'react';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import InputTextfield from '../../../atoms/textfield';
import { validateForm } from '../../../utilities/form';
import { BreadcrumbContext } from '../../Layout/Layout';
import siteTimingsConfig from './siteTimingConfig.json';
import classNames from 'classnames';
import StatesDropdown from '../../../atoms/StatesDropdown';
import { useDispatch } from 'react-redux';
import { sitesUpdated } from '../sitesSlice';
import CountryDropdown from '../../../atoms/CountryDropdown';
import { getCustomersDropdownData } from '../../dataAPI';
import { customersDropdownDataAdded } from '../../../appSlices';
import { getUserData } from '../../../utilities/app';

function AddSite({
  isEditSite,
  selectedSite
}) {
    const { isCustomerUser, customerID } = getUserData();
    const submitBtnRef = useRef(null);
    const dispatch = useDispatch();
    const editSite = isEditSite || window.location.pathname.includes('/sites/edit');
    const navigate = useNavigate();
    const { setCurrentBreadcrumb } = useContext(BreadcrumbContext);
    const customersDropdownData = useSelector(state => state.appData.customers);
    const [customers, setCustomers] = useState(customersDropdownData);
    const countries = useSelector(state => state.appData.countries);
    const [dayTimingsArr, setDayTimingsArr] = useState([
      ...siteTimingsConfig
    ]);

    useEffect(() => {
      isCustomerUser && !customerID && navigate('/dashboard');
    }, [isCustomerUser]);
    
    const [customerPhoneCountryCode, setCustomerPhoneCountryCode] = useState(61);
    const [formData, setFormData] = useState({
      ...selectedSite,  
      customeR_ID: customerID || '',
      sitE_NAME: selectedSite?.sitE_NAME || '',
      sitE_CONTACT: selectedSite?.sitE_CONTACT || '',
      emaiL_ID:  selectedSite?.emaiL_ID || '',
      phonE_NUMBER: selectedSite?.phonE_NUMBER || '',
      countrY_ID: selectedSite?.countrY_ID || 13,
      statE_ID: selectedSite?.statE_ID || 0,
      sitE_ADDRESS:  selectedSite?.sitE_ADDRESS || '',
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
      !editSite && setCurrentBreadcrumb([
        {
          "linkText": "Sites",
          "path": "/sites"
        },
        {
          "linkText": "Add New",
          "path": "/sites/add"
        }
      ]);
      (!customersDropdownData || !customersDropdownData.length) && getCustomersDropdownData(data => dispatch(customersDropdownDataAdded(data)));
    }, []);

    const initializeCustomerFormData = (selectedSiteData) => {
      if(!selectedSiteData) return;
      const {
        createD_BY,
        updateD_BY,
        creatioN_DATE,
        updatE_DATE,
        siteTimings,
        locations,
        locationsCount,
        ...selectedSite
      } = selectedSiteData;

      setFormData({
        ...selectedSite,
        customeR_ID: parseInt(selectedSite?.customeR_ID) || '',
        sitE_NAME: selectedSite?.sitE_NAME || '',
        sitE_CONTACT: selectedSite?.sitE_CONTACT || '',
        emaiL_ID:  selectedSite?.emaiL_ID || '',
        phonE_NUMBER: selectedSite?.phonE_NUMBER || '',
        countrY_ID: selectedSite?.countrY_ID || 13,
        statE_ID: selectedSite?.statE_ID || 0,
        sitE_ADDRESS:  selectedSite?.sitE_ADDRESS || '',
      });

      if(!siteTimings || !siteTimings.length) return;

      const mergedSavedDayTimings = dayTimingsArr.map((configDay) => {
        const isDayLastSaved = siteTimings.find(savedDay => savedDay?.oP_DAY?.toLowerCase() == configDay?.oP_DAY?.toLowerCase());
        return isDayLastSaved ? {...isDayLastSaved, isSelected: true} : {...configDay}
      });
      setDayTimingsArr(mergedSavedDayTimings);
    }

    useEffect(() => {
      if(!selectedSite) return;
      editSite && initializeCustomerFormData(selectedSite);
      setCustomerPhoneCountryCode(selectedSite?.phonE_NUMBER ? selectedSite?.phonE_NUMBER?.substr(1,2): 61);
    }, selectedSite);

    useEffect(() => {
      setCustomers(customersDropdownData);
    }, [customersDropdownData]);

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        let changedValue = value;
        if(type === 'checkbox') {
          changedValue = e.target.checked ? 1 : 0;
        }

        setFormData({
            ...formData,
            [name]: changedValue,
        });
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      formData.siteTimings = dayTimingsArr.filter(dayTiming => dayTiming.isSelected);
      const newErrors = validateForm(formData, {email: /\S+@\S+\.\S+/});
      setErrors(newErrors);


      if (Object.keys(newErrors).length === 0) {
        const phone = formData?.phonE_NUMBER?.includes('+') ? formData?.phonE_NUMBER?.substr(3) : formData?.phonE_NUMBER;
        formData.phonE_NUMBER = phone ? `${ customerPhoneCountryCode ? '+' + customerPhoneCountryCode + phone : phone}` : '';
        try {
              submitBtnRef.current.classList.add("button-loading");
              const response = await axios.request({
                  baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
                  url: editSite ? `/site/addSite/${selectedSite?.sitE_ID}` : '/site/addSite',
                  method: 'post',
                  data: formData
              });
              dispatch(sitesUpdated());
              editSite ? toast("Site details updated successfully.") : toast("Site details added successfully.");
              submitBtnRef.current.classList.remove("button-loading");
              
              navigate("/sites");
          }
          catch(e) {
              submitBtnRef.current.classList.remove("button-loading");
              toast('Something went wrong, please try again later.');
          }
      } else {
        toast('All the input fileds marked with * are mandatory to fill.');
      }
    };

    const handleTimingsChange = (value, index, key) => {
      dayTimingsArr[index][key] = value;

      setDayTimingsArr([
        ...dayTimingsArr,
      ])
    }

    const renderDayTimings = () => {
      const siteTimings = [];
      for (let index = 0; index < dayTimingsArr?.length; index++) {
        siteTimings.push(
          <div className={classNames({'add-site-timings-row': true, 'add-site-timings-row-selected': dayTimingsArr[index].isSelected})}>
              <div className="add-site-timings-day">
                <label className="add-site-timings-container">
                  <input type="checkbox" checked={dayTimingsArr[index].isSelected} onChange={(e) => handleTimingsChange(e.target.checked, index, 'isSelected')}/>
                  <span className={classNames({checkmark: true, selected: dayTimingsArr[index].isSelected})}>{dayTimingsArr[index].oP_DAY}</span>
                </label>
              </div>
              <div>
                <label className="add-site-timings-container add-site-timings-hrs">
                  <input type="checkbox" checked={dayTimingsArr[index].iS_WHOLE_DAY} onChange={(e) => handleTimingsChange(e.target.checked, index, 'iS_WHOLE_DAY')}/>
                  <span className={classNames({checkmark: true, selected: dayTimingsArr[index].iS_WHOLE_DAY})}>24hrs</span>
                </label>
              </div>
              <div className="add-site-form-input add-site-form-input-time">
                  <InputTextfield
                    type="time"
                    name="starT_DATETIME"
                    value={dayTimingsArr[index].starT_DATETIME}
                    placeholder="Email"
                    onChange={(e) => handleTimingsChange(e.target.value, index, 'starT_DATETIME')}/>
              </div>

              <div className="add-site-timings-to">
                to
              </div>

              <div className="add-site-form-input">
                  <InputTextfield
                    type="time"
                    name="enD_DATETIME"
                    value={dayTimingsArr[index].enD_DATETIME}
                    placeholder="Email"
                    hideError={true}
                    onChange={(e) => handleTimingsChange(e.target.value, index, 'enD_DATETIME')}/>
              </div>
          </div>
        )
      }
      return siteTimings;
    }

    const renderCustomerSelectionDropdown = () => (
      <div className="add-site-form-input add-site-form-input-select">
        <select value={formData?.customeR_ID} name="customeR_ID" id="customeR_ID" onChange={handleChange}>
          <option value="">Select Customer*</option>
          {
            customers.map((customer, index) => (
              <option key={`customeR_ID${index}`} value={parseInt(customer.customeR_ID)} selected>{customer.customeR_NAME}</option>
            ))
          }
        </select>
      </div>
    );

    return (
      <div className="add-site">
        <div className="add-site-heading">
          <div className="add-site-heading-text">
            {editSite ? 'Edit Site' : 'Add Site'}
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="add-site-form">
            <div className="add-site-form-content">
              { !isCustomerUser ? renderCustomerSelectionDropdown() : <></> }

              <div className="add-site-form-input">
                  <InputTextfield type="text" hideError={true} value={formData?.sitE_NAME} name="sitE_NAME" placeholder="Site Name*" error={errors.sitE_NAME} onChange={handleChange}/>
              </div>

              <div className="add-site-form-input">
                  <InputTextfield type="text" hideError={true} name="sitE_CONTACT" value={formData?.sitE_CONTACT} placeholder="Site Contact Name*" error={errors.sitE_CONTACT} onChange={handleChange}/>
              </div>

              <div className="add-site-form-input">
                  <InputTextfield type="text" hideError={true} value={formData?.emaiL_ID} name="emaiL_ID" placeholder="Email*" error={errors.emaiL_ID} onChange={handleChange}/>
              </div>

              <div className="add-site-form-input add-site-form-input-phone">
                <select onChange={(e) => setCustomerPhoneCountryCode(e.target.value)}>
                  {
                    countries.map((country, index) => (
                      <option key={`customerPhoneCountryCode${index}`} selected={parseInt(customerPhoneCountryCode) === country.phonecode} value={country.phonecode}>{country.phonecode}</option>
                    ))
                  }
                </select>
                <InputTextfield type="number" hideError={true} name="phonE_NUMBER" value={formData?.phonE_NUMBER?.includes('+') ? formData?.phonE_NUMBER?.substr(3) : formData?.phonE_NUMBER} placeholder="Phone*" error={errors.phonE_NUMBER} onChange={handleChange}/>
              </div>

              <div className="add-site-form-input add-site-form-input-select">
                <StatesDropdown value={formData?.statE_ID} label='Select State*' error={errors.statE_ID} onChange={handleChange} />
              </div>

              <div className="add-site-form-input">
                  <InputTextfield type="text" hideError={true} name="sitE_ADDRESS" value={formData?.sitE_ADDRESS} placeholder="Address*" error={errors.sitE_ADDRESS} onChange={handleChange}/>
              </div>
            </div>

            <div className="add-site-timings add-site-form-content">
              <div className="add-site-timings-heading">
                Operational Hours*
              </div>
              {renderDayTimings()}
              <div className="add-site-form-btn add-site-form-btn-submit">
                <button type="submit" ref={submitBtnRef}>
                    <span className="button__text">Save</span>
                </button>
            </div>
            </div>
          </div>
        </form>
        <CountryDropdown  displayDropdown={false}/>
      </div>
    );
};

export default AddSite;