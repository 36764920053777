import states from './states.json';

function StatesDropdown({
  value,
  onChange,
  label="Select State*",
  className
}) {
  return (
    <select value={value} name="statE_ID" id="statE_ID" onChange={onChange} className={className}>
      <option value="">{label}</option>
      {
        states.map((state, index) => (
          <option key={`statE_ID${index}`} value={state.statE_ID} selected>{state.statE_NAME}</option>
        ))
      }
    </select>
  );
}

export default StatesDropdown;