import { useContext, useEffect } from 'react';
import { BreadcrumbContext } from '../../components/Layout/Layout';
import { Link } from 'react-router-dom';

function Breadcrumb() {
  const  { currentBreadcrumb } = useContext(BreadcrumbContext);
  return (
    <div className="breadcrumb-container">
      <div className="breadcrumb-title">
        <img className="breadcrumb-title-home" src="/assets/sidenav/home.png"/>
        <span className="breadcrumb-title-text">
          <Link className="breadcrumb-title-text" to={'/home'}>
             Dashboard
          </Link>
        </span>
        <i class="fa fa-arrow-right" style={{fontSize: '20px'}}></i>
      </div>
      <ul className="breadcrumb">
        {
          currentBreadcrumb?.map(({path, linkText}) => (
            <li>
              <Link to={path}>
                {linkText}
              </Link>
            </li>
          ))
        }
      </ul>
    </div>
  );
};

export default Breadcrumb;