import { toast } from 'react-toastify';
import axios from 'axios';

export const deleteSite = async (row, refreshData) => {
  const { sitE_NAME } = row;
  //eslint-disable-line
  if (window.confirm(`Do you like to delete the site - ${sitE_NAME}`)) {
    //eslint-disable-line
    try {
      const response = await axios.request({
          baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
          url: `/site/sites/${row.sitE_ID}`,
          method: 'DELETE',
      });
      refreshData();
      toast(`Site ${row.sitE_NAME} deleted successfully !`)
    }
    catch(e) {
      toast('Something went wrong, please try again later.')
    }
  }
}

export const viewEditSite = (opType, row, navigate) => {
  localStorage.setItem('selectedSiteId', row.sitE_ID);
  localStorage.setItem('selectedSite', JSON.stringify(row));
  if(opType === 'edit') {
    navigate(`/sites/edit`);
  } else {
    localStorage.setItem('selectedCustomerId', row.customeR_ID);
    navigate('/customers/view/locations');
  }
}