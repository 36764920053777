import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { BreadcrumbContext } from '../../Layout/Layout';
import TableConfig from './customersTableConfig.json';
import StatesDropdown from '../../../atoms/StatesDropdown';
import { customersAdded, customerSelected } from '../customerSlice';
import Table from '../../../atoms/Table';
import classNames from 'classnames';

function AllCustomers() {
  const { setCurrentBreadcrumb } = useContext(BreadcrumbContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { header, visibleColumns, exportableColumns } = TableConfig;
  const customers = useSelector(state => state.customer.customers);
  const updateCustomers = useSelector(state => state.customer.updateCustomers);
  const [originalGridData, setOriginalCustomersData] = useState(customers);
  const [gridData, setCustomersData] = useState(customers);
  const [stateFilter, setStateFilter] = useState('');

  const getCustomersData = async () => {
    try {
      const response = await axios.request({
          baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
          url: '/GetAllCustomers',
          method: 'get',
      });
      const data = response?.data?.map(data => ({
        ...data,
        'contact_Name': data.customerContacts && data.customerContacts.length ? data.customerContacts[0].contacT_NAME : '',
        'status': data.activE_IND ? 'Approved': 'Pending',
        'sitesCount': data.sites.length || 0
      }));
      dispatch(customersAdded(data));
    }
    catch(e) {
      toast('Something went wrong, please try again later.')
    }
  };

  useEffect(() => {
    setCurrentBreadcrumb([
      {
        "linkText": "All Customers",
        "path": "/customers"
      }
    ]);
    return () => {}
  }, []);

  useEffect(() => {
    getCustomersData();
  }, [updateCustomers]);

  useEffect(() => {
    setOriginalCustomersData(customers);
    setCustomersData(customers);
  }, [customers]);

  const handleAddAction = () => navigate('/customers/add');

  const deleteCustomer = async (row) => {
    const { customeR_FIRSTNAME, customeR_EMAIL } = row;
    if (window.confirm(`Do you like to delete the customer - ${customeR_FIRSTNAME} ${customeR_EMAIL}`)) {
      try {
        const response = await axios.request({
            baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
            url: `/deleteCustomers/${row.customeR_ID}`,
            method: 'DELETE',
        });
        

        getCustomersData();
        toast(`Customer ${customeR_FIRSTNAME}-${customeR_EMAIL} deleted successfully !`)
      }
      catch(e) {
        toast('Something went wrong, please try again later.')
      }
    }
  }

  const viewEditCustomer = (opType, row) => {
    localStorage.setItem('selectedCustomerId', row.customeR_ID);
    localStorage.setItem('selectedCustomer', JSON.stringify(row));
    dispatch(customerSelected(row));
    opType === 'edit' ? navigate(`/customers/edit`) : navigate(`/customers/view`);
  }

  const filterCustomerData = (filterByAttr, selectedValue) => {
    setStateFilter(selectedValue);
    if(!selectedValue) return setCustomersData(originalGridData);

    const searchCustomersData = originalGridData.filter(customer => parseInt(customer[filterByAttr]) === parseInt(selectedValue));
    setCustomersData(searchCustomersData);
  };

  const generateDataColumn = (key, value, row) => {
    if(key === "logO_IMAGE") return (
      <img src={row[key] ? `data:image/jpeg;base64,${row[key]}` : "/assets/customers/default-pic.png"} className="customers-grid-logo"/>
    );

    if(key === "actions") return (
      <div className="customers-grid-row-actions">
        <button onClick={() => viewEditCustomer('view', row)}>
          <img src="/assets/customers/view.png"/>
        </button>
        <button onClick={() => viewEditCustomer('edit', row)}>
          <img src="/assets/customers/edit.png"/>
        </button>
        <button onClick={() => deleteCustomer(row)}>
          <img src="/assets/customers/delete.png"/>
        </button>
      </div>
    );
    
    if(key === 'status') {
      return (
        <div className={classNames({"customers-grid-status": true, "customers-grid-status-active": value === 'Approved'})}>{value}</div>
      )
    }

    if(key === 'sites') return row.sites.length || 0;

    return value;
  }

  const clearSelectedFilter = () => {
    setStateFilter('');
    setCustomersData([...originalGridData]);
  }

  const renderTableFilters = () => (
    <div className="customers-filter">
      <label for="state">Filter by</label>
      <StatesDropdown label="State" value={stateFilter} onChange={(e) => filterCustomerData('statE_ID', e.target.value)} />
      <button className="customers-filter-clear" onClick={clearSelectedFilter}>Clear Selected</button>
    </div>
  )

  return (
    <div className="customers">
      <Table
        renderTableFilters={renderTableFilters}
        handleAddAction={handleAddAction}
        TableTitle={`All Customers`}
        header={header}
        visibleColumns={visibleColumns}
        originalData={originalGridData}
        data={gridData}
        rowId={'customeR_ID'}
        exportableColumns={exportableColumns}
        generateDataColumn={generateDataColumn}
      />
    </div>
  );
};

export default AllCustomers;