import { createSlice } from '@reduxjs/toolkit'

const customersSlice = createSlice({
  name: 'customers',
  initialState: {
    customers: [],
    updateCustomer: 1,
    selectedCustomer: {}
  },
  reducers: {
    customersAdded(state, action) {
      state.customers = [...action.payload];
    },
    customersUpdated(state, action) {
      state.updateCustomer = Math.floor(Math.random()*10000);
    },
    customerSelected(state, action) {
      state.selectedCustomer = {...action.payload}
    },
  },
})

export const { customersAdded, customersUpdated, customerSelected } = customersSlice.actions
export default customersSlice.reducer