function Tooltip({
  children,
  tooltipText
}) {
  return (
    <div class="vr-tooltip">
      {children}
      <span class="vr-tooltip-text">{tooltipText}</span>
    </div>
  )
};

export default Tooltip;


