import { useContext, useEffect, useState } from 'react';
import { BreadcrumbContext } from '../../Layout/Layout';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';
import AddLocation from './../AddLocation/AddLocation';
import { getLocationURL } from '../../../constants/apiUrl';

function EditLocation() {
  const { setCurrentBreadcrumb } = useContext(BreadcrumbContext);
  const selectedLocationId = localStorage.getItem('selectedLocationId');
  const selectedLocationData = JSON.parse(localStorage.getItem('selectedLocation'));
  const [selectedLocation, setSelectedLocation] = useState(selectedLocationData);
  const navigate = useNavigate();

  const updateBreadcrumb = (locatioN_NAME) => {
    setCurrentBreadcrumb([
      {
        "linkText": "Locations",
        "path": "/locations"
      },
      {
        "linkText": locatioN_NAME,
        "path": "/locations", //"/customers/view/locations"
      },
      {
        "linkText": "Edit",
        "path": "/locations/edit"
      }
    ]);
  }

  const getLocationData = async () => {
    if(!selectedLocationId) {
      navigate('/locations');
    };

    try {
      const response = await axios.request({
          baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
          url: `${getLocationURL}/${selectedLocationId}`,
          method: 'get',
      });
      
      const location = response?.data;
      updateBreadcrumb(location?.locatioN_NAME || 'selected location')
      setSelectedLocation(location);
    }
    catch(e) {
      toast('Something went wrong, please try again later.')
    }
  };
  useEffect(() => {
    updateBreadcrumb(selectedLocationData?.locatioN_NAME || 'selected location')
    !selectedLocationData && getLocationData();
  }, []);

  return (
    <div className="edit-location">
      <AddLocation isEditLocation={true} selectedLocation={selectedLocation}/>
    </div>
  )
};

export default EditLocation;