import { createSlice } from '@reduxjs/toolkit'

const appSlice = createSlice({
  name: 'appData',
  initialState: {
    countries: [],
    customers: [],
  },
  reducers: {
    countriesAdded(state, action) {
      state.countries = [...action.payload];
    },
    customersDropdownDataAdded(state, action) {
      state.customers = [...action.payload];
    },
  },
})

export const { countriesAdded, customersDropdownDataAdded } = appSlice.actions
export default appSlice.reducer