import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Table from '../../atoms/Table';
import { useDispatch, useSelector } from 'react-redux';
import { BreadcrumbContext } from '../Layout/Layout';
import InputTextfield from '../../atoms/textfield';
import TableConfig from './tableConfig.json';
import { addGridData } from './passSessionsSlice';

function PassSessions() {
  const { setCurrentBreadcrumb } = useContext(BreadcrumbContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { header, rows, visibleColumns, exportableColumns } = TableConfig;
  const data = useSelector(state => state.passSessions.data);
  const updateGridData = useSelector(state => state.passSessions.updateGridData);
  const [originalGridData, setOriginalGridData] = useState(data);
  const [gridData, setGridData] = useState(data);
  const [selectedFilters, setSelectedFilters] = useState({});

  const selectedCustomer = JSON.parse(localStorage.getItem('selectedCustomer') || null);
  const selectedLocation = JSON.parse(localStorage.getItem('selectedLocation') || null);
  const selectedPass = JSON.parse(localStorage.getItem('selectedPass') || null);
  const selectedSite = JSON.parse(localStorage.getItem('selectedSite') || {});

  const updateBreadcrumb = () => {
    setCurrentBreadcrumb([
      {
        "linkText": "All Customers",
        "path": "/customers"
      },
      {
        "linkText": selectedCustomer?.customeR_FIRSTNAME || '-',
        "path": "/customers/view"
      },
      {
        "linkText": selectedSite?.sitE_NAME || '-',
        "path": "/customers/view/locations"
      },
      {
        "linkText": selectedLocation?.locatioN_NAME,
        "path": "/passes"
      },
      {
        "linkText": `Pass ${selectedPass?.passID}`,
        "path": "/passSessions"
      }
    ]);
  };

  const getGridData = async () => {
    try {
      const response = await axios.request({
          baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
          url: '/GetPasses',
          method: 'get',
      });
      const data = response?.data?.map(data => ({
        ...data,
        'contact_Name': data.customerContacts && data.customerContacts.length ? data.customerContacts[0].contacT_NAME : '',
        'status': data.activE_IND ? 'Active': 'Inactive'
      }));
      dispatch(addGridData(data));
    }
    catch(e) {
      toast('Something went wrong, please try again later.')
    }
  };

  useEffect(() => {
    updateBreadcrumb();
    return () => {}
  }, []);

  useEffect(() => {
    dispatch(addGridData(rows)); //getGridData();
  }, [updateGridData]);

  useEffect(() => {
    setOriginalGridData(data);
    setGridData(data);
  }, [data]);

  const filterData = (filterByAttr, selectedValue) => {
    setSelectedFilters({
      [filterByAttr]: selectedValue
    });

    if(!selectedValue) return setGridData(originalGridData);

    const searchData = [];
    originalGridData.forEach((row) => {
      row[filterByAttr]?.toString()?.includes(selectedValue) && searchData.push(row);
    });
    setGridData(searchData);
  };

  const clearSelectedFilter = () => {
    setSelectedFilters({
      passID: '',
      activE_IND: ''
    });
    setGridData([...originalGridData]);
  }

  const renderTableFilters = () => (
    <div className="pass-sessions-filter">
      <label for="state">Filter by</label>
      <InputTextfield type="date" placeholder={'DD-MM-YYYY'} name="openSessionStartDateFilter" value={selectedFilters.openSessioStartDate} id="openSessionDate" onChange={(e) => filterData('openSessioStartDate', e.target.value)}/>
      <label className='pass-sessions-filter-label'>to</label>
      <InputTextfield type="date" placeholder={'DD-MM-YYYY'} name="openSessionEndDateFilter" value={selectedFilters.openSessionEndDate} id="openSessionEndDate" onChange={(e) => filterData('openSessionEndDate', e.target.value)}/>
      <label for="state">Filter by</label>
      <InputTextfield type="time" name="openSessionStartTimeFilter" value={selectedFilters.openSessioStartTime} id="openSessioStartTime" onChange={(e) => filterData('openSessioStartTime', e.target.value)}/>
      <label className='pass-sessions-filter-label'>to</label>
      <InputTextfield type="time" name="openSessionEndTimeFilter" value={selectedFilters.openSessionEndTime} id="openSessionEndTime" onChange={(e) => filterData('openSessionEndTime', e.target.value)}/>
      <button className="pass-sessions-filter-clear" onClick={clearSelectedFilter}>Clear Selected</button>
    </div>
  )

  return (
    <div className="pass-sessions">
      <Table
        renderTableFilters={renderTableFilters}
        TableTitle={`Pass ${selectedPass.passID}`}
        header={header}
        visibleColumns={visibleColumns}
        originalData={originalGridData}
        data={gridData}
        rowId={'sessionId'}
        exportableColumns={exportableColumns}
      />
    </div>
  );
};

export default PassSessions;