import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { validateForm } from '../../utilities/form';
import InputTextfield from '../../atoms/textfield';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { verifyOtpURL } from '../../constants/apiUrl';
import { useNavigate } from "react-router-dom";
import axios from 'axios';

function OTP_Form() {
  const otpBtnRef = useRef(null);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    otp1: '',
    otp2: '',
    otp3: '',
    otp4: '',
    otp5: '',
    otp6: '',
  });

  const email = localStorage.getItem('email');

  useEffect(() => {
    if (!email) {
      toast("OTP verification expired, please contact site admin.");
    };
  }, []);

  const formFields = [
    { name: 'otp1', type: 'number' },
    { name: 'otp2', type: 'number' },
    { name: 'otp3', type: 'number' },
    { name: 'otp4', type: 'number' },
    { name: 'otp5', type: 'number' },
    { name: 'otp6', type: 'number' },
  ];

  const [errors, setErrors] = useState({});

  const generateForm = (formFields, handleChange, classname) => formFields.map(({name, type, placeholder}, index) => (
    <>
      <div className={classNames(classname, `${classname}-${name}`)}>
        <InputTextfield maxlength="1" max="9" min="0" value={formData[name]} type={type} name={name} placeholder={placeholder} onChange={handleChange}/>
      </div>
      {index !== formFields.length-1 && (
        <span className="otp-form-input-separator">-</span>
      )}
    </>
  ));

  const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
          ...formData,
          [name]: value?.replace(/[^0-9]/g,'')%10,
      });
      
      document.getElementsByName('otp'+ (parseInt(e.target.name.substr(-1))+1))[0]?.focus();
  };

  const handleSubmit = async (e) => {
      e.preventDefault();
      if (!email) {
        toast("OTP verification expired, please contact site admin.");
        return;
      };

      const newErrors = validateForm(formData);
      setErrors(newErrors);

      if (Object.keys(newErrors).length === 0) {
        const otpInput = Object.values(formData).reduce((total, currentNum) => total + currentNum.toString(), '');
        try {
          otpBtnRef.current.classList.add("button-loading");
          const response = await axios.request({
              baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
              url: `${verifyOtpURL}?otpInput=${otpInput}&email=${email}`,
              method: 'post',
              data: {
                  email,
                  otpInput
              }
          });
          toast("OTP verification successfully completed.")
          otpBtnRef.current.classList.remove("button-loading");
          
          navigate("/signin");
      }
      catch(e) {
          otpBtnRef.current.classList.remove("button-loading");
          toast('OTP verification failed, please try again after sometime.')
      }
      } else {
        toast('Please enter all the OTP numbers.');
      }
  };
  return (
    <div className="otp-form-content">
        <div className="otp-form-logo"></div>
        <div className="otp-form-label">
          Enter Verification Code
        </div>
        <div className="otp-form-label otp-form-label-note">
          We've sent a code to {localStorage.getItem('email') || 'your mail'}
        </div>
        <form onSubmit={handleSubmit}>
          <div className="otp-form-inputs-container">
            { generateForm(formFields, handleChange, 'otp-form-input') }
          </div>
          {errors && Object.keys(errors).length ? (
            <div className="error-message">
                Please enter all OTP numbers
            </div>
          ) : null}
          <div className="otp-form-resend-otp" style={{display: 'none'}}>
            Didn't get a code? <Link to="/otp">Click to resend</Link>
          </div>
          <div className="otp-form-btn otp-form-login-btn">
            <button type="submit" ref={otpBtnRef}>Verify</button>
          </div>
        </form>
    </div>
  );
};

export default OTP_Form;