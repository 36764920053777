import axios from "axios";

export const getCustomerData = async (customerId, callbackFn) => {
  if(!customerId) return;

  try {
    const response = await axios.request({
        baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
        url: `/GetCustomerById/${customerId}`,
        method: 'get',
    });
    callbackFn(response?.data);
  }
  catch(e) {
    console.log('Something went wrong in Customer Data api, please try again later.')
  }
};