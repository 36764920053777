
import './footer.scss';

function Footer() {
    return (
        <div className="footer">
            © 2024 VRFID 
        </div>
    );
};

export default Footer;