import Footer from "../../Footer/Footer";
import Background from "../Background/Background";
import SetPasswordForm from "./SetPasswordForm";

function Signin() {
    return (
      <div className="set-password">
        <div className="set-password-background">
          <Background />
        </div>
        <div className="set-password-form">
          <SetPasswordForm/>
          <Footer/>
        </div>
      </div>
    );
};

export default Signin;