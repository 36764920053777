import { useContext, useEffect } from 'react';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import InputTextfield from '../../../atoms/textfield';
import { validateForm } from '../../../utilities/form';
import { BreadcrumbContext } from '../../Layout/Layout';
import siteTimingsConfig from './siteTimingConfig.json';
import classNames from 'classnames';
import StatesDropdown from '../../../atoms/StatesDropdown';
import { useDispatch } from 'react-redux';
import { locationsUpdated } from '../locationsSlice';
import { addLocationURL } from '../../../constants/apiUrl';
import CountryDropdown from '../../../atoms/CountryDropdown';
import { getSitesData } from '../../Sites/sitesAPI';
import { sitesAdded } from '../../Sites/sitesSlice';
import ToggleSwitch from '../../../atoms/Switch';
import { getCustomersDropdownData } from '../../dataAPI';
import { customersDropdownDataAdded } from '../../../appSlices';
import { getUserData } from '../../../utilities/app';

function AddLocation({
  isEditLocation,
  selectedLocation
}) {
    const submitBtnRef = useRef(null);
    const dispatch = useDispatch();
    const editLocation = isEditLocation || window.location.pathname.includes('/locations/edit');
    const navigate = useNavigate();
    const { setCurrentBreadcrumb } = useContext(BreadcrumbContext);
    const countries = useSelector(state => state.appData.countries);
    const sites = useSelector(state => state.site.sites);
    const customersDropdownData = useSelector(state => state.appData.customers);
    const [sitesData, setSitesData] = useState(sites);
    const [customerSitesData, setCustomerSitesData] = useState([]);
    const [customers, setCustomers] = useState(customersDropdownData);
    const [dayTimingsArr, setDayTimingsArr] = useState([
      ...siteTimingsConfig
    ]);
    const [formData, setFormData] = useState({
      customeR_ID: '',
      sitE_ID: '',
      locatioN_NAME: '',
      via: '',
      nearby:  '',
      level:  '',
      capacity:  '',
      pasS_TYPES:  '',
      iS_HARDWARE: false,
      locationHardwares: {
        camera: '',
        reader: '',
        door: '',
        counter: '',
        electronic: '',
        app: ''
      }
    });
    const [errors, setErrors] = useState({});
    const { isCustomerUser, customerID } = getUserData();

    useEffect(() => {
      isCustomerUser && !customerID && navigate('/dashboard');
    }, [customerID]);

    useEffect(() => {
      !editLocation && setCurrentBreadcrumb([
        {
          "linkText": "Locations",
          "path": "/locations"
        },
        {
          "linkText": "Add New",
          "path": "/locations/add"
        }
      ]);
      (!customersDropdownData || !customersDropdownData.length) && getCustomersDropdownData(data => dispatch(customersDropdownDataAdded(data)));
    }, []);
  
    useEffect(() => {
      if(!sites || !sites.length) {
        getSitesData(data => dispatch(sitesAdded(data)), customerID);
      } else {
        setSitesData(sites);
      }
    }, [sites]);

    const initializeFormData = (selectedLocationData) => {
      if(!selectedLocationData) return;
      const {
        createD_BY,
        updateD_BY,
        creatioN_DATE,
        updatE_DATE,
        locationTiming,
        locationHardwares,
        ...selectedLocation
      } = selectedLocationData;

      const selectedSites = sitesData.find(site => parseInt(site.sitE_ID) === parseInt(selectedLocation?.sitE_ID));
      setSitesDropdownData(selectedSites?.customeR_ID);
      setFormData({
        ...selectedLocation,
        customeR_ID: selectedSites?.customeR_ID || '',
        sitE_ID: selectedLocation?.sitE_ID || '',
        locatioN_NAME: selectedLocation?.locatioN_NAME || '',
        via: selectedLocation?.via || '',
        nearby:  selectedLocation?.nearby || '',
        level:  selectedLocation?.level || '',
        capacity:  selectedLocation?.capacity || '',
        pasS_TYPES:  selectedLocation?.pasS_TYPES || '',
        iS_HARDWARE: locationHardwares && locationHardwares.length && Object.keys(locationHardwares[0]).length,
        locationHardwares: {
          camera: '',
          reader: '',
          door: '',
          counter: '',
          electronic: '',
          app: '',
          ...locationHardwares[0]
        }
      });

      if(!locationTiming || !locationTiming.length) return;

      const mergedSavedDayTimings = dayTimingsArr.map((configDay) => {
        const isDayLastSaved = locationTiming.find(savedDay => savedDay?.oP_DAY?.toLowerCase() == configDay?.oP_DAY?.toLowerCase());
        return isDayLastSaved ? {...isDayLastSaved, isSelected: true} : {...configDay}
      });
      setDayTimingsArr(mergedSavedDayTimings);
    }

    useEffect(() => {
      if(!selectedLocation) return;
      editLocation && initializeFormData(selectedLocation);
    }, []);

    useEffect(() => {
      setCustomers(customersDropdownData);
    }, [customersDropdownData]);

    useEffect(() => {
      isCustomerUser && customerID && (formData.customeR_ID !== customerID) && handleCustomerChange({target: {value: customerID, name: "customeR_ID", type: "input"}});
    }, [sitesData]);

    const setSitesDropdownData = (customeR_ID) => {
      const customerSites = sitesData.filter(site => parseInt(site.customeR_ID) === parseInt(customeR_ID));
      setCustomerSitesData(customerSites);
    }

    const handleCustomerChange = (e) => {
      const { value } = e.target;
      setFormData({
        ...formData,
        sitE_ID: '',
      });
      setSitesDropdownData(value);
      handleChange(e);
    }

    const handleSiteChange = (e) => {
      const { value } = e.target;
      const selectedSites = sitesData.find(site => parseInt(site.sitE_ID) === parseInt(value));
      if(selectedSites.siteTimings && selectedSites.siteTimings.length) {
        const mergedSavedDayTimings = siteTimingsConfig.map((configDay) => {
          const isDayLastSaved = selectedSites.siteTimings.find(savedDay => savedDay?.oP_DAY?.toLowerCase() == configDay?.oP_DAY?.toLowerCase());
          return isDayLastSaved ? {...isDayLastSaved, isSelected: true} : {...configDay}
        });
        setDayTimingsArr(mergedSavedDayTimings);
      }
      handleChange(e);
    }

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        let changedValue = value;
        if(type === 'checkbox') {
          changedValue = e.target.checked ? 1 : 0;
        }

        setFormData({
            ...formData,
            [name]: changedValue,
        });
    };

    const handleHardwareChange = (e) => {
      if(!formData?.iS_HARDWARE) return;

      const { name, value } = e.target;

      setFormData({
          ...formData,
          locationHardwares: {
            ...formData.locationHardwares,
            [name]: value,
          }
      });
  };

    const handleSubmit = async (e) => {
      e.preventDefault();
      formData.locationTiming = dayTimingsArr.filter(dayTiming => dayTiming.isSelected);
      const locationHardwares = {
        ...formData.locationHardwares
      };
      delete formData.locationHardwares;
      formData.locationHardwares = [{...locationHardwares}];
      formData.iS_HARDWARE = formData.iS_HARDWARE ? 1 : 0;
      const newErrors = validateForm(formData, {email: /\S+@\S+\.\S+/});
      setErrors(newErrors);


      if (Object.keys(newErrors).length === 0) {
        try {
              submitBtnRef.current.classList.add("button-loading");
              const response = await axios.request({
                  baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
                  url: editLocation ? `${addLocationURL}/${selectedLocation?.locatioN_ID}` : addLocationURL,
                  method: 'post',
                  data: formData
              });
              dispatch(locationsUpdated());
              editLocation ? toast("Location details updated successfully.") : toast("Location details added successfully.");
              submitBtnRef.current.classList.remove("button-loading");
              
              navigate("/locations");
          }
          catch(e) {
              submitBtnRef.current.classList.remove("button-loading");
              toast('Something went wrong, please try again later.');
          }
      } else {
        toast('All the input fileds marked with * are mandatory to fill.');
      }
    };

    const handleTimingsChange = (value, index, key) => {
      dayTimingsArr[index][key] = value;

      setDayTimingsArr([
        ...dayTimingsArr,
      ])
    }

    const renderDayTimings = () => {
      const locationTiming = [];
      for (let index = 0; index < dayTimingsArr?.length; index++) {
        locationTiming.push(
          <div className={classNames({'add-location-timings-row': true, 'add-location-timings-row-selected': dayTimingsArr[index].isSelected})}>
              <div className="add-location-timings-day">
                <label className="add-location-timings-container">
                  <input type="checkbox" checked={dayTimingsArr[index].isSelected} onChange={(e) => handleTimingsChange(e.target.checked, index, 'isSelected')}/>
                  <span className={classNames({checkmark: true, selected: dayTimingsArr[index].isSelected})}>{dayTimingsArr[index].oP_DAY}</span>
                </label>
              </div>
              <div>
                <label className="add-location-timings-container add-location-timings-hrs">
                  <input type="checkbox" checked={dayTimingsArr[index].iS_WHOLE_DAY} onChange={(e) => handleTimingsChange(e.target.checked, index, 'iS_WHOLE_DAY')}/>
                  <span className={classNames({checkmark: true, selected: dayTimingsArr[index].iS_WHOLE_DAY})}>24hrs</span>
                </label>
              </div>
              <div className="add-location-form-input add-location-form-input-time">
                  <InputTextfield
                    type="time"
                    name="starT_DATETIME"
                    value={dayTimingsArr[index].starT_DATETIME}
                    placeholder="Email"
                    onChange={(e) => handleTimingsChange(e.target.value, index, 'starT_DATETIME')}/>
              </div>

              <div className="add-location-timings-to">
                to
              </div>

              <div className="add-location-form-input">
                  <InputTextfield
                    type="time"
                    name="enD_DATETIME"
                    value={dayTimingsArr[index].enD_DATETIME}
                    placeholder="Email"
                    hideError={true}
                    onChange={(e) => handleTimingsChange(e.target.value, index, 'enD_DATETIME')}/>
              </div>
          </div>
        )
      }
      return locationTiming;
    }

    const renderHardwareForm = () => (
      <>
        <div className="add-location-heading add-location-heading-hardware">
          <div className="add-location-heading-text">
            Add Hardware
          </div>
        </div>

        <div className="add-location-heading">
          <div className="add-location-heading-text add-location-heading-text-toggle">
            Hardware: <ToggleSwitch name="iS_HARDWARE" onChange={handleChange} selected={formData?.iS_HARDWARE}/>
          </div>
        </div>

        <div className={classNames({"add-location-form-input": true, "add-location-form-input-disabled": !formData?.iS_HARDWARE})}>
            <InputTextfield type="text" hideError={true} value={formData?.locationHardwares?.camera} readonly={!formData?.iS_HARDWARE} name="camera" placeholder="Camera e.g. Hikvision" error={errors.camera} onChange={handleHardwareChange}/>
        </div>

        <div className={classNames({"add-location-form-input": true, "add-location-form-input-disabled": !formData?.iS_HARDWARE})}>
            <InputTextfield type="text" hideError={true} value={formData?.locationHardwares?.reader} readonly={!formData?.iS_HARDWARE} name="reader" placeholder="Reader:" error={errors.reader} onChange={handleHardwareChange}/>
        </div>

        <div className={classNames({"add-location-form-input": true, "add-location-form-input-disabled": !formData?.iS_HARDWARE})}>
            <InputTextfield type="text" hideError={true} value={formData?.locationHardwares?.door} readonly={!formData?.iS_HARDWARE} name="door" placeholder="Door:" error={errors.door} onChange={handleHardwareChange}/>
        </div>

        <div className={classNames({"add-location-form-input": true, "add-location-form-input-disabled": !formData?.iS_HARDWARE})}>
            <InputTextfield type="text" hideError={true} value={formData?.locationHardwares?.counter} readonly={!formData?.iS_HARDWARE} name="counter" placeholder="Counter:" error={errors.counter} onChange={handleHardwareChange}/>
        </div>

        <div className={classNames({"add-location-form-input": true, "add-location-form-input-disabled": !formData?.iS_HARDWARE})}>
            <InputTextfield type="text" hideError={true} value={formData?.locationHardwares?.electronic} readonly={!formData?.iS_HARDWARE} name="electronic" placeholder="Electronic:" error={errors.electronic} onChange={handleHardwareChange}/>
        </div>

        <div className={classNames({"add-location-form-input": true, "add-location-form-input-disabled": !formData?.iS_HARDWARE})}>
            <InputTextfield type="text" hideError={true} value={formData?.locationHardwares?.app} readonly={!formData?.iS_HARDWARE} name="app" placeholder="App:" error={errors.app} onChange={handleHardwareChange}/>
        </div>
      </>
    )

    return (
      <div className="add-location">
        <div className="add-location-heading">
          <div className="add-location-heading-text">
            {editLocation ? 'Edit Location' : 'Add Location'}
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="add-location-form">
            <div className="add-location-form-content">
              { !isCustomerUser ? (
                  <div className="add-location-form-input add-location-form-input-select">
                    <select value={formData?.customeR_ID} name="customeR_ID" id="customeR_ID" onChange={handleCustomerChange}>
                      <option value="">Select Customer*</option>
                      {
                        customers.map((customer, index) => (
                          <option key={`customeR_ID${index}`} value={customer.customeR_ID} selected>{customer.customeR_NAME}</option>
                        ))
                      }
                    </select>
                  </div>
                ) : <></>
              }

              <div className="add-location-form-input add-location-form-input-select">
                <select value={formData?.sitE_ID} name="sitE_ID" id="sitE_ID" onChange={handleSiteChange}>
                  <option value="">Select Site Name*</option>
                  {
                    customerSitesData.map((site, index) => (
                      <option key={`sitE_ID${index}`} value={site.sitE_ID}>{site.sitE_NAME}</option>
                    ))
                  }
                </select>
              </div>

              <div className="add-location-form-input">
                  <InputTextfield type="text" hideError={true} value={formData?.locatioN_NAME} name="locatioN_NAME" placeholder="Location Name*" error={errors.locatioN_NAME} onChange={handleChange}/>
              </div>

              <div className="add-location-form-input">
                  <InputTextfield type="text" hideError={true} name="via" value={formData?.via} placeholder="Via*" error={errors.via} onChange={handleChange}/>
              </div>

              <div className="add-location-form-input">
                  <InputTextfield type="text" hideError={true} value={formData?.nearby} name="nearby" placeholder="Nearby*" error={errors.nearby} onChange={handleChange}/>
              </div>

              <div className="add-location-form-input">
                  <InputTextfield type="text" hideError={true} value={formData?.level} name="level" placeholder="Level*" error={errors.level} onChange={handleChange}/>
              </div>

              <div className="add-location-form-input">
                  <InputTextfield type="text" hideError={true} value={formData?.capacity} name="capacity" placeholder="Capacity*" error={errors.capacity} onChange={handleChange}/>
              </div>

              <div className="add-location-form-input">
                  <InputTextfield type="text" hideError={true} value={formData?.pasS_TYPES} name="pasS_TYPES" placeholder="Pass Types* (you can add multiple pass types)" error={errors.pasS_TYPES} onChange={handleChange}/>
              </div>
              
              { renderHardwareForm() }
            </div>

            <div className="add-location-timings add-location-form-content">
              <div className="add-location-timings-heading">
                Operational Hours*
              </div>
              {renderDayTimings()}
              <div className="add-location-form-btn add-location-form-btn-submit">
                <button type="submit" ref={submitBtnRef}>
                    <span className="button__text">Save</span>
                </button>
            </div>
            </div>
          </div>
        </form>
        <CountryDropdown displayDropdown={false}/>
      </div>
    );
};

export default AddLocation;