import { useContext, useEffect, useState } from 'react';
import { BreadcrumbContext } from '../../Layout/Layout';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';
import { getStateName } from '../../../atoms/StatesDropdown/states';
import { deleteSite, viewEditSite } from '../../Sites/Sites/sitesUtilities';
import { useSelector } from 'react-redux';
import CountryDropdown from '../../../atoms/CountryDropdown';
import { getUserData } from '../../../utilities/app';

function ViewCustomer() {
  const { setCurrentBreadcrumb } = useContext(BreadcrumbContext);
  const { isCustomerUser } = getUserData();
  const [searchKey, setSearchKey] = useState(null);
  const selectedCust = JSON.parse(localStorage.getItem('selectedCustomer') || null);
  const countries = useSelector(state => state.appData.countries);
  const [sitesData, setSitesData] = useState(selectedCust?.sites || []);
  const [originalSitesData, setOriginalSitesData] = useState(selectedCust?.sites || []);
  const navigate = useNavigate();
  const selectedCustomerId = localStorage.getItem('selectedCustomerId');
  const [selectedCustomer, setSelectedCustomer] = useState(selectedCust);

  const updateBreadcrumb = (customeR_FIRSTNAME) => {
    setCurrentBreadcrumb(isCustomerUser ? [
      {
        "linkText": "My summary",
        "path": "/customers/view"
      }]: [
        {
          "linkText": "All Customers",
          "path": "/customers"
        },
        {
          "linkText": customeR_FIRSTNAME,
          "path": "/customers/view"
        },
      ]
    );
  }

  const getCustomerData = async () => {
    if(!selectedCustomerId) {
      navigate('/customers');
    };

    try {
      const response = await axios.request({
          baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
          url: `/GetCustomerById/${selectedCustomerId}`,
          method: 'get',
      });
      updateBreadcrumb(response?.data?.customeR_FIRSTNAME);
      setSelectedCustomer(response?.data);
      setSitesData(response?.data?.sites)
      setOriginalSitesData(response?.data?.sites)
    }
    catch(e) {
      toast('Something went wrong, please try again later.')
    }
  };

  const handleTableSearch = () => {
    if(!searchKey) return setSitesData(originalSitesData);

    const searchData = [];
    originalSitesData.forEach((row) => {
      Object.values(row).toString().toLowerCase().includes(searchKey.toLowerCase()) && searchData.push(row);
    });
    setSitesData(searchData);
  }

  const getSelectedCountryName = (countryCode) => {
    const selectedCountry =  countries.filter(country => country.countrY_ID === countryCode);
    return selectedCountry[0]?.nicename || '';
  };

  const viewLocations = (selectedSite, locationsCount) => {
    if(!locationsCount) {
      toast('No locations are currently present under this site.');
      return;
    }
    localStorage.setItem('selectedSite', JSON.stringify(selectedSite));
    navigate('/customers/view/locations');
  }

  const viewPasses = (selectedSite, passesCount) => {
    if(!passesCount) {
      toast('No passes are currently present under this site.');
      return;
    }
    localStorage.setItem('selectedSite', JSON.stringify(selectedSite));
    navigate('/passholders');
  }

  useEffect(() => {
    updateBreadcrumb(selectedCustomer?.customeR_FIRSTNAME);
    !selectedCustomer && getCustomerData();
  }, []);

  useEffect(() => {
    (!selectedCustomer || parseInt(selectedCustomerId) !== parseInt(selectedCustomer.customeR_ID)) && getCustomerData();
  }, [selectedCustomerId, selectedCustomer]);

  const renderSiteDetails = () => sitesData.map((site, siteIndex) => (
    <div className="view-customer-site">
      <div className="view-customer-site-info-container">
        <div className="view-customer-site-info">
          <div className="view-customer-site-label">Site Name</div>
          <div className="view-customer-site-value">{site.sitE_NAME}</div>
        </div>

        <div className="view-customer-site-card">
          <div className="view-customer-site-label">
            Locations
            <div className="view-customer-site-value">{site?.locations?.length || 0}</div>
          </div>
          <div className="view-customer-site-btn">
            <button onClick={() => viewLocations(site, site?.locations?.length || 0)}>
              <img src="/assets/customers/view/view.png"/>
            </button>
          </div>
        </div>

        <div className="view-customer-site-card">
          <div className="view-customer-site-label">
            Passes
            <div className="view-customer-site-value">{site?.passes?.length || 0}</div>
          </div>
          <div className="view-customer-site-btn">
            <button onClick={() => viewPasses(site, site?.passes?.length || 0)}>
              <img src="/assets/customers/view/view.png"/>
            </button>
          </div>
        </div>
      </div>

      <div className="view-customer-site-actions">
        <button onClick={() => viewEditSite('edit', site, navigate)}>
          <img src="/assets/customers/view/edit.png"/>
        </button>

        <button onClick={() => deleteSite(site, getCustomerData)}>
          <img src="/assets/customers/view/delete.png"/>
        </button>
      </div>
    </div>
  ));

  const renderSiteActionsBar = () => (
    <div className="vrfid-datatable-grid-heading">
      <div>My Summary</div>
      <div className="vrfid-datatable-grid-actions">
        <input name="tableSearchKey" placeholder="Type in any table data text to search" onChange={(e) => setSearchKey(e.target.value)}/>
        <button className="vrfid-datatable-grid-actions-search" onClick={handleTableSearch}>
          <img src="/assets/customers/search.png"/>
        </button>
        <button className="customers-grid-actions-add" onClick={() => navigate('/sites/add')}>
          <img src="/assets/customers/add.png"/>
        </button>
      </div>
    </div>
  )

  return (
    <div className="view-customer">
      {!isCustomerUser ? (
        <div className="view-customer-details">
        <div className="view-customer-details-img">
          <img id="previewImg" src={selectedCustomer?.logO_IMAGE ? `data:image/jpeg;base64,${selectedCustomer?.logO_IMAGE}` : "/assets/customers/default-pic.png"}/>
        </div>
        <div className="view-customer-details-info">
          <div className="view-customer-details-info1">
            <div className="view-customer-details-info-row">
              <div className="view-customer-details-info-label">
                Customer Name
              </div>
              <div className="view-customer-details-info-value">
                {selectedCustomer?.customeR_FIRSTNAME}
              </div>
            </div>

            <div className="view-customer-details-info-row">
              <div className="view-customer-details-info-label">
                Email
              </div>
              <div className="view-customer-details-info-value">
                {selectedCustomer?.customeR_EMAIL}
              </div>
            </div>

            <div className="view-customer-details-info-row">
              <div className="view-customer-details-info-label">
                Phone
              </div>
              <div className="view-customer-details-info-value">
                {selectedCustomer?.phonE_NUMBER}
              </div>
            </div>

            <div className="view-customer-details-info-row">
              <div className="view-customer-details-info-label">
                Notes
              </div>
              <div className="view-customer-details-info-value view-customer-details-info-value-notes">
                {selectedCustomer?.notes}
              </div>
            </div>
          </div>
          <div className="view-customer-details-info2">
          <div className="view-customer-details-info-row">
              <div className="view-customer-details-info-label">
                Website
              </div>
              <div className="view-customer-details-info-value">
                {selectedCustomer?.websitE_URL}
              </div>
            </div>

            <div className="view-customer-details-info-row">
              <div className="view-customer-details-info-label">
                Country
              </div>
              <div className="view-customer-details-info-value">
                {getSelectedCountryName(selectedCustomer?.countrY_ID)}
              </div>
            </div>

            <div className="view-customer-details-info-row">
              <div className="view-customer-details-info-label">
                State
              </div>
              <div className="view-customer-details-info-value">
                {getStateName(selectedCustomer?.statE_ID)?.statE_NAME}
              </div>
            </div>

            <div className="view-customer-details-info-row">
              <div className="view-customer-details-info-label">
                Address
              </div>
              <div className="view-customer-details-info-value">
                {selectedCustomer?.addresS_LINE1}
              </div>
            </div>
          </div>
        </div>
        </div>) : <></>
      }

      <div className="view-customer-sites">
        <div className="view-customer-heading">
          {!isCustomerUser ? 
            selectedCustomer?.customeR_FIRSTNAME :
            renderSiteActionsBar()
          }
        </div>
        {  sitesData?.length ? renderSiteDetails() : (
          <div className="view-customer-site">
            <div className="view-customer-site-info-container" style={{color: 'white'}}>No site records found</div>
          </div>
        )}
      </div>
      <CountryDropdown  displayDropdown={false}/>
    </div>
  )
};

export default ViewCustomer;