import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BreadcrumbContext } from '../../Layout/Layout';
import TableConfig from './sitesTableConfig.json';
import { useDispatch, useSelector } from 'react-redux';
import { sitesAdded } from '../sitesSlice';
import { deleteSite, viewEditSite } from './sitesUtilities';
import { getSitesData } from '../sitesAPI';
import { getCustomersDropdownData } from '../../dataAPI';
import { customersDropdownDataAdded } from '../../../appSlices';
import Table from '../../../atoms/Table/Table';
import { getUserData } from '../../../utilities/app';

function AllCustomers() {
  const { setCurrentBreadcrumb } = useContext(BreadcrumbContext);
  const { isCustomerUser, customerID } = getUserData();
  const navigate = useNavigate();
  const { header, customerHeader, exportableColumns, visibleColumns, customerExportableColumns, customerVisibleColumns } = TableConfig;
  const dispatch = useDispatch();
  const sites = useSelector(state => state.site.sites);
  const updateSites = useSelector(state => state.site.updateSites);
  const customersDropdownData = useSelector(state => state.appData.customers);
  const [originalGridData, setOriginalSitesData] = useState([]);
  const [contactNameData, setContactNameData] = useState([]);
  const [gridData, setSitesData] = useState(sites);
  const [customers, setCustomers] = useState(customersDropdownData);
  const [selectedFilters, setSelectedFilters] = useState({});

  useEffect(() => {
    isCustomerUser && !customerID && navigate('/dashboard');
  }, [customerID]);

  const getContactName = (sites) => {
    let contacts = [];
    sites.forEach(site => {
      !contacts.includes(site.sitE_CONTACT) && contacts.push(site.sitE_CONTACT);
    });
    setContactNameData([...contacts]);
  }

  useEffect(() => {
    setCurrentBreadcrumb([
      {
        "linkText": isCustomerUser ? "My Summary" : "Customers",
        "path": isCustomerUser ? "/customers/view" : "/customers"
      },
      {
        "linkText": isCustomerUser ? "All Sites" : "Sites",
        "path": "/sites"
      }
    ]);
    (!customersDropdownData || !customersDropdownData.length) && getCustomersDropdownData(data => dispatch(customersDropdownDataAdded(data)));
    return () => {}
  }, []);

  useEffect(() => {
    getSitesData(data => dispatch(sitesAdded(data)), customerID);
  }, [updateSites]);

  useEffect(() => {
    getSitesData(data => dispatch(sitesAdded(data)), customerID);
  }, []);

  useEffect(() => {
    setCustomers(customersDropdownData);
  }, [customersDropdownData]);

  useEffect(() => {
    setSitesData(sites);
    setOriginalSitesData(sites);
    getContactName(sites);
  }, [sites]);

  const handleAddAction = () => navigate('/sites/add');

  const clearSelectedFilter = () => {
    setSelectedFilters({
      sitE_ID: '',
      customeR_ID: '',
      sitE_CONTACT: ''
    });
    setSitesData([...originalGridData]);
  }

  const filterCustomerData = (filterByAttr, selectedValue) => {
    setSelectedFilters({
      [filterByAttr]: selectedValue
    });

    if(!selectedValue) return setSitesData(originalGridData);

    const searchCustomersData = [];
    originalGridData.forEach((row) => {
      row[filterByAttr]?.toString()?.includes(selectedValue) && searchCustomersData.push(row);
    });
    setSitesData(searchCustomersData);
  };

  const generateDataColumn = (key, value, row) => {
    if(key === "actions") return (
      <div className="sites-grid-row-actions">
        <button onClick={() => viewEditSite('view', row, navigate)}>
          <img src="/assets/customers/view.png"/>
        </button>
        <button onClick={() => viewEditSite('edit', row, navigate)}>
          <img src="/assets/customers/edit.png"/>
        </button>
        <button onClick={() => deleteSite(row, () => getSitesData(data => dispatch(sitesAdded(data)), customerID))}>
          <img src="/assets/customers/delete.png"/>
        </button>
      </div>
    );

    return value;
  }

  const renderTableFilters = () => (
    <div className="sites-filter">
      <label for="state">Filter by</label>
      <select name="siteFilter" value={selectedFilters.sitE_ID} id="state" onChange={(e) => filterCustomerData('sitE_ID', e.target.value)}>
        <option value="">Site Name</option>
        {
          originalGridData.map(site => (
            <option value={site.sitE_ID}>{site.sitE_NAME}</option>
          ))
        }
      </select>
      { !isCustomerUser ? (
        <>
          <label for="state">Filter by</label>
          <select name="customerFilter" id="customer" value={selectedFilters.customeR_ID} onChange={(e) => filterCustomerData('customeR_ID', e.target.value)}>
            <option value="">Customer Name</option>
            {
              customers.map(customer => (
                <option value={customer.customeR_ID}>{customer.customeR_NAME}</option>
              ))
            }
          </select>
          </>
      ) : <></> }
      <label for="state">Filter by</label>
      <select name="contactFilter" id="contact" value={selectedFilters.sitE_CONTACT} onChange={(e) => filterCustomerData('sitE_CONTACT', e.target.value)}>
        <option value="">Contact Name</option>
        {
          contactNameData.map(name => (
            <option value={name}>{name}</option>
          ))
        }
      </select>
      <button className="sites-filter-clear" onClick={clearSelectedFilter}>Clear Selected</button>
    </div>
  );

  return (
    <div className="sites">
      <Table
        renderTableFilters={renderTableFilters}
        handleAddAction={handleAddAction}
        TableTitle={`Sites`}
        header={isCustomerUser ? customerHeader : header}
        visibleColumns={isCustomerUser ? customerVisibleColumns : visibleColumns}
        exportableColumns={isCustomerUser ? customerExportableColumns : exportableColumns}
        originalData={originalGridData}
        data={gridData}
        rowId={'sitE_ID'}
        generateDataColumn={generateDataColumn}
      />
    </div>
  );
};

export default AllCustomers;