import { toast } from 'react-toastify';
import axios from 'axios';
import { deleteLocationURL } from '../../../constants/apiUrl';

export const deleteLocation = async (row, refreshData) => {
  const { locatioN_NAME } = row;
  if (window.confirm(`Do you like to delete the location - ${locatioN_NAME}`)) {
    try {
      const response = await axios.request({
          baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
          url: `${deleteLocationURL}/${row.locatioN_ID}`,
          method: 'DELETE',
      });
      refreshData();
      toast(`Location ${row.locatioN_NAME} deleted successfully !`)
    }
    catch(e) {
      toast('Something went wrong, please try again later.')
    }
  }
}

export const viewEditLocation = (opType, row, navigate) => {
  localStorage.setItem('selectedLocationId', row.locatioN_ID);
  localStorage.setItem('selectedLocation', JSON.stringify(row));
  if(opType === 'edit') {
    navigate(`/locations/edit`);
  } else {
    navigate('/customers/view/locations');
  }
}