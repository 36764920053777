import Footer from "../Footer/Footer";
import SigninSignupBackground from "../SigninSignupBackground/SigninSignupBackground";
import SignupForm from "./SignupForm";

function Signup() {
    return (
      <div className="signup">
        <SigninSignupBackground />
        <div className="signup-form">
          <SignupForm/>
          <Footer/>
        </div>
      </div>
    );
};

export default Signup;