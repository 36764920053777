import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { BreadcrumbContext } from '../../Layout/Layout';
import TableConfig from './passholdersTableConfig.json';
import { passholdersAdded, passholderselected } from '../passholdersSlice';
import Table from '../../../atoms/Table/Table';
import classNames from 'classnames';

function Passholder() {
  const { setCurrentBreadcrumb } = useContext(BreadcrumbContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { header, rows, visibleColumns, exportableColumns } = TableConfig;
  const passholders = useSelector(state => state.passholder.passholders);
  const updatePassholders = useSelector(state => state.passholder.updatePassholders);
  const [originalGridData, setOriginalPassholdersData] = useState(passholders);
  const [gridData, setPassholdersData] = useState(passholders);
  const [selectedFilters, setSelectedFilters] = useState({});

  const getPassholdersData = async () => {
    try {
      const response = await axios.request({
          baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
          url: '/GetPassholder',
          method: 'get',
      });
      const data = response?.data?.map(data => ({
        ...data,
        'contact_Name': data.customerContacts && data.customerContacts.length ? data.customerContacts[0].contacT_NAME : '',
        'status': data.status ? 'Active': 'Inactive'
      }));
      dispatch(passholdersAdded(data));
    }
    catch(e) {
      toast('Something went wrong, please try again later.')
    }
  };

  useEffect(() => {
    setCurrentBreadcrumb([
      {
        "linkText": "Customers",
        "path": "/customers"
      },
      {
        "linkText": "All Passholders",
        "path": "/passholders"
      }
    ]);
    return () => {}
  }, []);

  useEffect(() => {
    dispatch(passholdersAdded(rows)); //getPassholdersData();
  }, [updatePassholders]);

  useEffect(() => {
    setOriginalPassholdersData(passholders);
    setPassholdersData(passholders);
  }, [passholders]);

  const deletePassholder = async (row) => {
    return;
    const { customeR_FIRSTNAME, customeR_EMAIL } = row;
    //eslint-disable-line
    if (window.confirm(`Do you like to delete the customer - ${customeR_FIRSTNAME} ${customeR_EMAIL}`)) {
      //eslint-disable-line
      try {
        const response = await axios.request({
            baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
            url: `/deletePassholders/${row.customeR_ID}`,
            method: 'DELETE',
        });
        

        getPassholdersData();
        toast(`Customer ${customeR_FIRSTNAME}-${customeR_EMAIL} deleted successfully !`)
      }
      catch(e) {
        toast('Something went wrong, please try again later.')
      }
    }
  }

  const viewEditPassholder = (opType, row) => {
    return;
    localStorage.setItem('selectedCustomerId', row.customeR_ID);
    dispatch(passholderselected(row));
    opType === 'edit' ? navigate(`/passholders/edit`) : navigate(`/passholders/view`);
  }

  const filterPassholderData = (filterByAttr, selectedValue) => {
    setSelectedFilters({
      [filterByAttr]: selectedValue
    });

    if(!selectedValue) return setPassholdersData(originalGridData);

    const searchData = [];
    originalGridData.forEach((row) => {
      row[filterByAttr]?.toString()?.includes(selectedValue) && searchData.push(row);
    });
    setPassholdersData(searchData);
  };

  const generateDataColumn = (key, value, row) => {
    if(key === "actions") return (
      <div className="passholders-grid-row-actions">
        <button onClick={() => viewEditPassholder('view', row)}>
          <img src="/assets/customers/view.png"/>
        </button>
        <button onClick={() => viewEditPassholder('edit', row)}>
          <img src="/assets/customers/edit.png"/>
        </button>
        <button onClick={() => deletePassholder(row)}>
          <img src="/assets/customers/delete.png"/>
        </button>
      </div>
    );
    
    if(key === 'status') {
      return (
        <div className={classNames({"passholders-grid-status": true, "passholders-grid-status-active": value === 'Active'})}>{value}</div>
      )
    }

    if(key === 'sites') return row.sites.length || 0;

    return value;
  }

  const clearSelectedFilter = () => {
    setSelectedFilters({
      passID: '',
    });
    setPassholdersData([...originalGridData]);
  }

  const renderTableFilters = () => (
    <div className="passholders-filter">
      <label for="state">Filter by</label>
      <select name="siteFilter" value={selectedFilters.passID} id="state" onChange={(e) => filterPassholderData('passID', e.target.value)}>
        <option value="">Pass ID</option>
        {
          originalGridData.map(pass => (
            <option value={pass.passID}>{pass.passID}</option>
          ))
        }
      </select>
    
      <select name="siteFilter" value={selectedFilters.passID} id="state" onChange={(e) => filterPassholderData('passID', e.target.value)}>
        <option value="">Pass Type</option>
        {
          originalGridData.map(pass => (
            <option value={pass.passID}>{pass.passType}</option>
          ))
        }
      </select>

      <select name="siteFilter" value={selectedFilters.passID} id="state" onChange={(e) => filterPassholderData('status', e.target.value)}>
        <option value="">Status</option>
        <option value="1">{'Active'}</option>
        <option value="0">{'In Active'}</option>
      </select>

      <select name="siteFilter" value={selectedFilters.passID} id="state" onChange={(e) => filterPassholderData('passID', e.target.value)}>
        <option value="">Customer ID</option>
        {
          originalGridData.map(pass => (
            <option value={pass.passID}>{pass.customeR_ID}</option>
          ))
        }
      </select>

      <select name="siteFilter" value={selectedFilters.passID} id="state" onChange={(e) => filterPassholderData('passID', e.target.value)}>
        <option value="">Site Name</option>
        {
          originalGridData.map(pass => (
            <option value={pass.passID}>{pass.siteName}</option>
          ))
        }
      </select>

      <select name="siteFilter" value={selectedFilters.passID} id="state" onChange={(e) => filterPassholderData('passID', e.target.value)}>
        <option value="">Locations</option>
        {
          originalGridData.map(pass => (
            <option value={pass.passID}>{pass.locationName}</option>
          ))
        }
      </select>
      
      <button className="passholders-filter-clear" onClick={clearSelectedFilter}>Clear Selected</button>
    </div>
  )

  return (
    <div className="passholders">
      <Table
        renderTableFilters={renderTableFilters}
        TableTitle={`All Passholders`}
        header={header}
        visibleColumns={visibleColumns}
        originalData={originalGridData}
        data={gridData}
        rowId={'passID'}
        exportableColumns={exportableColumns}
        generateDataColumn={generateDataColumn}
      />
    </div>
  );
};

export default Passholder;