import classNames from 'classnames';
import InputTextfield from '../atoms/textfield';
import CountryDropdown from '../atoms/CountryDropdown';

export const validateForm = (data, regEx) => {
    const errors = {};

    for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
            const element = data[key];
            console.log(element);
            if (!element?.toString().trim()) {
                errors[key] = 'This is a required field';
            } else if (regEx && regEx[key] && !regEx[key].test(element)) {
                errors[key] = 'The entered value is invalid';
            }
        }
    }
    return errors;
};

export const generateForm = (formFields, errors, handleChange, classname) => formFields.map(({name, type, placeholder}) => (
    <div className={classNames(classname, `${classname}-${name}`, {[`${classname}-error`]:errors[name]})}>
      { type === 'countryDropdown' ? <CountryDropdown name={name} displayDropdown={true} placeholder={placeholder} error={errors[name]} onChange={handleChange}/> : <InputTextfield type={type} name={name} placeholder={placeholder} error={errors[name]} onChange={handleChange}/> }
    </div>
));