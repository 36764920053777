export const getUserData = () => {
  const { userType, status, customerId, name, logO_IMAGE, } = JSON.parse(localStorage.getItem('userData')) || {};
    let customerID = parseInt(customerId);
    customerID && localStorage.setItem('selectedCustomerId', customerID);
    const isCustomerUser = userType === 'customer';
    return {
      userType,
      status,
      customerID,
      isCustomerUser,
      name,
      logO_IMAGE,
    }
};