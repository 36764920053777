export const sitesURL = '/site/sites';
export const customerSitesURL = '/site/siteByCustId';
export const locationsURL = '/location/locations';
export const customerLocationsURL = '/site/siteByLocationId'
export const getLocationURL = '/location/locationById';
export const addLocationURL = '/location/addLocation';
export const deleteLocationURL = '/location/deleteLocation';
export const getCustomerDropdownDataURL = '/site/siteCustomer';
export const countriesURL = '/countries';
export const getAllCustomersURL = '/GetAllCustomers';
export const verifyOtpURL = '/VerifyOTP';
export const addCustomerURL = '/addCustomers';
export const updateCustomerURL = '/updateCustomer';
export const customersURL = '/customers';
export const addUserURL = '/AddUser';

