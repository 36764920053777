import Footer from "../Footer/Footer";
import SigninSignupBackground from "../SigninSignupBackground/SigninSignupBackground";
import OtpForm from "./OTP_Form";

function OTP_validation() {
    return (
      <div className="otp">
        <SigninSignupBackground />
        <div className="otp-form">
          <OtpForm/>
          <Footer/>
        </div>
      </div>
    );
};

export default OTP_validation;