import { useRef, useState } from 'react';
import { generateForm, validateForm } from '../../utilities/form';
import InputTextfield from '../../atoms/textfield';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';
import { addCustomerURL, addUserURL } from '../../constants/apiUrl';

function SignupForm() {
    const navigate = useNavigate();
    const submitBtnRef = useRef(null);
    const passwordRef = useRef(null);
    const confirmPasswordRef = useRef(null);
    const [formData, setFormData] = useState({
      name: '',
      companyName: '',
      email: '',
      country: 13,
      phone: '',
      password: '',
      confirmPassword: ''
    });

    const formFields = [
      {
        name: 'name', type: 'text', placeholder: 'Name'
      },
      {
        name: 'companyName', type: 'text', placeholder: 'Company Name'
      },
      {
        name: 'email', type: 'text', placeholder: 'Email'
      },
      {
        name: 'country', type: 'countryDropdown', placeholder: 'Country'
      },
      {
        name: 'phone', type: 'number', placeholder: 'Phone'
      },
    ]

    const [errors, setErrors] = useState({});

    const togglePassword = (passwordRef) => {
      const passwordField = passwordRef.current;
      const passwordInput = passwordField.querySelector('input');
      const passwordIcon = passwordField.querySelector('.password-toggle-icon');
      if (passwordInput.type === "password") {
          passwordInput.type = "text";
          passwordIcon.classList.remove("fa-eye");
          passwordIcon.classList.add("fa-eye-slash");
        } else {
          passwordInput.type = "password";
          passwordIcon.classList.remove("fa-eye-slash");
          passwordIcon.classList.add("fa-eye");
        }
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const validateFormData = () => {
      const newErrors = validateForm(formData, {email: /\S+@\S+\.\S+/, phone: /^\d+$/});
      (formData.password !== formData.confirmPassword) && (newErrors.confirmPassword = 'Password and Confirm password is different');
      return newErrors;
    }

    const getCustomerFormData = () => {
      return {
        activE_IND: 0,
        customeR_EMAIL: formData.email || '',  
        customeR_FIRSTNAME: formData?.companyName || '',
        phonE_NUMBER: formData?.phone || '',
        countrY_ID: formData?.country || 13,
        customerContacts: [
          {
            contacT_EMAIL: formData.email,
            contacT_NAME: formData.name,
            contacT_PHONE_NUMBER: formData.phone,
            contacT_ROLE: ''
          }
        ],
        statE_ID: 1,
        addresS_LINE1:  '',
        websitE_URL:  '',
        notes:  '',
      };
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = validateFormData();
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            // Form submission logic here
            try {
              submitBtnRef.current.classList.add("button-loading");
              await axios.request({
                baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
                url: addCustomerURL,
                method: 'post',
                data: getCustomerFormData()
              });
              const response = await axios.request({
                  baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
                  url: addUserURL,
                  method: 'post',
                  data: {
                    useriD_LOGIN: formData.email,
                    password: formData.password,
                  }  
              });
              toast('Sign-up successfull, please check the mail for OTP verification.');
              submitBtnRef.current.classList.remove("button-loading");
              localStorage.setItem('email', formData.email);
              navigate("/otp");
          }
          catch(e) {
              submitBtnRef.current.classList.remove("button-loading");
              toast('Sign-up failed, please try after some time.')
          }
        }
    };

    return (
      <div className="signup-form-content">
          <div className="signup-form-logo"></div>
          <div className="signup-form-label">
            New User Details
          </div>
          <div className="signup-form-label signup-form-label-note" style={{display: 'none'}}>
            Initial information fields
          </div>
          <form onSubmit={handleSubmit}>
            { generateForm(formFields, errors, handleChange, 'signup-form-input') }
            <div className="input-container set-password-form-input set-password-form-input-password" ref={passwordRef}>
                <InputTextfield type="password" name="password" placeholder="Password" hideError={true} error={errors.password} onChange={handleChange}/>
                <i className="password-toggle-icon fa fa-eye icon" onClick={() => togglePassword(passwordRef)}></i>
            </div>
            { errors.password && (
                <div className="error-message">
                    {errors.password}
                </div>
            )}

            <div className="input-container set-password-form-input set-password-form-input-password" ref={confirmPasswordRef}>
                <InputTextfield type="password" name="confirmPassword" placeholder="Confirm Password" hideError={true} error={errors.confirmPassword} onChange={handleChange}/>
                <i className="password-toggle-icon fa fa-eye icon" onClick={() => togglePassword(confirmPasswordRef)}></i>
            </div>
            { errors.confirmPassword && (
                <div className="error-message">
                    {errors.confirmPassword}
                </div>
            )}
            <div className="signup-form-btn signup-form-login-btn">
              <button type="submit" ref={submitBtnRef}>
                <span class="button__text">Sign Up</span>
              </button>
            </div>
          </form>
      </div>
    );
};

export default SignupForm;