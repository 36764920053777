import { configureStore } from '@reduxjs/toolkit'
import sitesReducers from '../components/Sites/sitesSlice';
import customersReducres from '../components/Customers/customerSlice';
import appReduxers from '../appSlices';
import locationsReducers from '../components/Locations/locationsSlice';
import passholderReducers from '../components/Passholders/passholdersSlice';
import passesReducers from '../components/Passes/passesSlice';
import passSessionsReducers from '../components/PassSessions/passSessionsSlice';

export const store = configureStore({
  reducer: {
    site: sitesReducers,
    customer: customersReducres,
    location: locationsReducers,
    passholder: passholderReducers,
    passes: passesReducers,
    passSessions: passSessionsReducers,
    appData: appReduxers
  },
})