import './signinSignupBackground.scss';

function SigninSignupBackground() {
    return (
        <div className="signin-backdrop">
            <div className="signin-backdrop-img">
            </div>
        </div>
    );
};

export default SigninSignupBackground;