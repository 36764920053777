import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { BreadcrumbContext } from '../Layout/Layout';
import TableConfig from './passesTableConfig.json';
import { addGridData, gridRowSelected } from './passesSlice';
import Tooltip from '../../atoms/Tooltip';
import Table from '../../atoms/Table/Table';
import classNames from 'classnames';

function Passes() {
  const { setCurrentBreadcrumb } = useContext(BreadcrumbContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { header, rows, visibleColumns, exportableColumns } = TableConfig;
  const [columnHeaders, setColumnHeaders] = useState(header);
  const passes = useSelector(state => state.passes.passes);
  const updateGridData = useSelector(state => state.passes.updateGridData);
  const [originalGridData, setOriginalGridData] = useState(passes);
  const [gridData, setGridData] = useState(passes);
  const [searchKey, setSearchKey] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({});

  const selectedCustomer = JSON.parse(localStorage.getItem('selectedCustomer') || null);
  const selectedLocation = JSON.parse(localStorage.getItem('selectedLocation') || null);
  const selectedSite = JSON.parse(localStorage.getItem('selectedSite') || {});

  const updateBreadcrumb = () => {
    setCurrentBreadcrumb([
      {
        "linkText": "All Customers",
        "path": "/customers"
      },
      {
        "linkText": selectedCustomer?.customeR_FIRSTNAME || '-',
        "path": "/customers/view"
      },
      {
        "linkText": selectedSite?.sitE_NAME || '-',
        "path": "/customers/view/locations"
      },
      {
        "linkText": selectedLocation?.locatioN_NAME,
        "path": "/passes"
      }
    ]);
  };

  const getPassesData = async () => {
    try {
      const response = await axios.request({
          baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
          url: '/GetPasses',
          method: 'get',
      });
      const data = response?.data?.map(data => ({
        ...data,
        'contact_Name': data.customerContacts && data.customerContacts.length ? data.customerContacts[0].contacT_NAME : '',
        'status': data.activE_IND ? 'Active': 'Inactive'
      }));
      dispatch(addGridData(data));
    }
    catch(e) {
      toast('Something went wrong, please try again later.')
    }
  };

  useEffect(() => {
    updateBreadcrumb();
    return () => {}
  }, []);

  useEffect(() => {
    dispatch(addGridData(rows)); //getPassesData();
  }, [updateGridData]);

  useEffect(() => {
    setOriginalGridData(passes);
    setGridData(passes);
  }, [passes]);

  const sortTable = (columnKey, colIndex) => {
    const columns = [...columnHeaders];
    let sortedData = [];
    if(columns[colIndex]?.ascSort) {
      columns[colIndex].ascSort = false;
      columns[colIndex].descSort = true;
      sortedData = gridData.slice().sort((a,b) => b[columnKey]?.toString()?.localeCompare(a[columnKey]));
    } else {
      columns[colIndex].ascSort = true;
      columns[colIndex].descSort = false;
      sortedData = gridData.slice().sort((a,b) => {
        return a[columnKey]?.toString()?.localeCompare(b[columnKey])
    });
    }
    setColumnHeaders([
      ...columns,
    ]);
    setGridData(sortedData);
  }

  const generateColumnHeaders = () => columnHeaders.map(({columnName, columnKey, isSortable, descSort, ascSort}, colIndex) => (
    <th>
      {
        isSortable ? (
          <div key={`col${colIndex}`} className='table-column' onClick={() => sortTable(columnKey, colIndex)}>
            {columnName}
            <span>
              <i className={classNames({"fa fa-sort-asc": true, "active": ascSort})} style={{height:'5px'}}></i>
              <i className={classNames({"fa fa-sort-desc": true, "active": descSort})}></i>
            </span>
            <Tooltip tooltipText="Sorting by Alphabetical order">
              <i class="fa fa-info-circle"></i>
            </Tooltip>
          </div>
        ) :
        <>{columnName}</>
      }
    </th>
  ));

  const deleteRow = async (row) => {
    return;
    const { customeR_FIRSTNAME, customeR_EMAIL } = row;
    //eslint-disable-line
    if (window.confirm(`Do you like to delete the customer - ${customeR_FIRSTNAME} ${customeR_EMAIL}`)) {
      //eslint-disable-line
      try {
        const response = await axios.request({
            baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
            url: `/deletePass/${row.customeR_ID}`,
            method: 'DELETE',
        });
        

        getPassesData();
        toast(`Customer ${customeR_FIRSTNAME}-${customeR_EMAIL} deleted successfully !`)
      }
      catch(e) {
        toast('Something went wrong, please try again later.')
      }
    }
  }

  const viewEditRow = (opType, row) => {
    localStorage.setItem('selectedPass', JSON.stringify(row));
    dispatch(gridRowSelected(row));
    opType === 'view' ? navigate(`/passsessions`) : console.log('no edit');//navigate(`/passes/edit`);
  }

  const filterData = (filterByAttr, selectedValue) => {
    setSelectedFilters({
      [filterByAttr]: selectedValue
    });

    if(!selectedValue) return setGridData(originalGridData);

    const searchData = [];
    originalGridData.forEach((row) => {
      row[filterByAttr]?.toString()?.includes(selectedValue) && searchData.push(row);
    });
    setGridData(searchData);
  };

  const generateDataColumn = (key, value, row) => {
    if(key === "actions") return (
      <div className="passes-grid-row-actions">
        <button onClick={() => viewEditRow('view', row)}>
          <img src="/assets/customers/view.png"/>
        </button>
        <button onClick={() => viewEditRow('edit', row)}>
          <img src="/assets/customers/edit.png"/>
        </button>
        <button onClick={() => deleteRow(row)}>
          <img src="/assets/customers/delete.png"/>
        </button>
      </div>
    );

    if(key === 'status') {
      return (
        <div className={classNames({"passes-grid-status": true, "passes-grid-status-active": value === 'Active'})}>{value}</div>
      )
    }

    return value;
  }

  const clearSelectedFilter = () => {
    setSelectedFilters({
      passID: '',
      activE_IND: ''
    });
    setGridData([...originalGridData]);
  }

  const renderTableFilters = () => (
    <div className="passes-filter">
      <label for="state">Filter by</label>
      <select name="siteFilter" value={selectedFilters.passID} id="state" onChange={(e) => filterData('passID', e.target.value)}>
        <option value="">Pass Type</option>
        {
          originalGridData.map(pass => (
            <option value={pass.passID}>{pass.passType}</option>
          ))
        }
      </select>

      <label for="state">Filter by</label>
      <select name="siteFilter" value={selectedFilters.passID} id="state" onChange={(e) => filterData('activE_IND', e.target.value)}>
        <option value="">Status</option>
        <option value="1">{'Active'}</option>
        <option value="0">{'In Active'}</option>
      </select>
      <button className="passes-filter-clear" onClick={clearSelectedFilter}>Clear Selected</button>
    </div>
  )

  return (
    <div className="passes">
      <Table
        renderTableFilters={renderTableFilters}
        TableTitle={`All Passes`}
        header={header}
        visibleColumns={visibleColumns}
        originalData={originalGridData}
        data={gridData}
        rowId={'passID'}
        exportableColumns={exportableColumns}
        generateDataColumn={generateDataColumn}
      />
    </div>
  );
};

export default Passes;