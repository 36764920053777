import { useContext, useEffect, useState } from 'react';
import { BreadcrumbContext } from "../Layout/Layout";
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { customersAdded } from '../Customers/customerSlice';
import { sitesAdded } from '../Sites/sitesSlice';
import { locationsAdded } from '../Locations/locationsSlice';
import axios from 'axios';
import { getAllCustomersURL } from '../../constants/apiUrl';
import { getLocationsData, getCustomerLocationsData } from './../Locations/locationsAPI';
import { getSitesData } from '../Sites/sitesAPI';
import { getCustomersDropdownData } from '../dataAPI';
import { customersDropdownDataAdded } from '../../appSlices';
import { customerSelected } from '../Customers/customerSlice';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { getUserData } from '../../utilities/app';

function Dashboard() {
    const navigate = useNavigate();
    const { setCurrentBreadcrumb } = useContext(BreadcrumbContext);
    const selectedCustomer = useSelector(state => state.customer.selectedCustomer);
    const { userType, status, customerID } = getUserData();
    const isCustomerUser = userType === 'customer';
    useEffect(() => {
      !userType && navigate('/signin');
    }, [userType]);

    const [dashboardData, setDashboardData] = useState([]);
    const customers = useSelector(state => state.customer.customers);
    const sites = useSelector(state => state.site.sites);
    const locations = useSelector(state => state.location.locations);
    const [locationsData, setLocationsData] = useState(locations);
    const dispatch = useDispatch();

    const getAllCustomersData = async () => {
      try {
        const response = await axios.request({
            baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
            url: getAllCustomersURL,
            method: 'get',
        });
        const data = response?.data?.map(data => ({
          ...data,
          'contact_Name': data.customerContacts && data.customerContacts.length ? data.customerContacts[0].contacT_NAME : '',
          'status': data.activE_IND ? 'Approved': 'Pending'
        }));
        dispatch(customersAdded(data));
      }
      catch(e) {
      }
    };

    useEffect(() => {
      setCurrentBreadcrumb([
        {
          "linkText": "Home",
          "path": "/home"
        }
      ]);
      getSitesData(data => dispatch(sitesAdded(data)), customerID);
      if(isCustomerUser) {
        getCustomerLocationsData(
          customerID,
          (locations, customerData) => {
            dispatch(customerSelected(customerData))
            setLocationsData(locations);
            dispatch(locationsAdded(locations));
          }
        );
      } else {
        getAllCustomersData();
        getCustomersDropdownData(data => dispatch(customersDropdownDataAdded(data)));
        getLocationsData(data => dispatch(locationsAdded(data)), customerID);
      }

    }, []);

    useEffect(() => {
      setLocationsData(locations);
    }, [locations]);

    useEffect(() => {
      if(isCustomerUser) {
        setDashboardData([
          {
            name: 'Sites',
            count: sites?.length || 0,
            path: '/sites',
            imagePath: '/assets/dashboard/sites.png'
          },
          {
            name: 'Locations',
            count: locationsData?.length || 0,
            path: '/locations',
            imagePath: '/assets/dashboard/locations.png'
          },
          {
            name: 'Passes',
            count: 5,
            path: '/passholders',
            imagePath: '/assets/dashboard/passes.png'
          }
        ]);
      } else {
        setDashboardData([
          {
            name: 'Customer',
            count: customers?.length || 0,
            path: '/customers',
            imagePath: '/assets/dashboard/customers.png'
          },
          {
            name: 'Sites',
            count: sites?.length || 0,
            path: '/sites',
            imagePath: '/assets/dashboard/sites.png'
          },
          {
            name: 'Locations',
            count: locationsData?.length || 0,
            path: '/locations',
            imagePath: '/assets/dashboard/locations.png'
          },
          {
            name: 'Passes',
            count: 5,
            path: '/passholders',
            imagePath: '/assets/dashboard/passes.png'
          }
        ]);
      }
    }, [customers?.length, sites?.length, locationsData?.length]);

    const generateCards = () => dashboardData.map(data => (
      <div className={classNames({"dashboard-card": true, "dashboard-card-long": isCustomerUser})}>
        <div className="dashboard-card-content">
          <div className="dashboard-card-details">
            <div className="dashboard-card-name">{data.name}</div>
            <div className="dashboard-card-count">{data.count}</div>
            <div className="dashboard-card-link">
              <Link to={data.path}>
                View All
              </Link>
          </div>
        </div>
        <div className="dashboard-card-img">
          <img src={data.imagePath}/>
        </div>
        </div>
        
      </div>
    ));

    return (
      <div className="dashboard">
        <div className="dashboard-title">Dashboard</div>
        <div className="dashboard-cards">
          { status ? generateCards() : null }
        </div>
      </div>
    );
};

export default Dashboard;