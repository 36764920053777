import Footer from "../../Footer/Footer";
import Background from "../Background/Background";
import RecoverPasswordForm from "./RecoverPasswordForm";

function Signin() {
    return (
      <div className="forgot-password">
        <div className="forgot-password-background">
          <Background />
        </div>
        <div className="forgot-password-form">
          <RecoverPasswordForm/>
          <Footer/>
        </div>
      </div>
    );
};

export default Signin;