import { useContext, useEffect, useState } from 'react';
import { BreadcrumbContext } from '../../Layout/Layout';
import { useNavigate } from "react-router-dom";
import AddCustomer from "../AddCustomer";
import axios from 'axios';
import { toast } from 'react-toastify';
import { getCustomerData } from './../AddCustomer/customerAPI';

function EditCustomer() {
  const { setCurrentBreadcrumb } = useContext(BreadcrumbContext);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const navigate = useNavigate();
  const selectedCustomerId = localStorage.getItem('selectedCustomerId');

  const fetchCustomerData = async () => {
    if(!selectedCustomerId) {
      navigate('/customers');
    };

    try {
      getCustomerData(selectedCustomerId, data => {
        setCurrentBreadcrumb([
          {
            "linkText": "All Customers",
            "path": "/customers"
          },
          {
            "linkText": data?.customeR_FIRSTNAME,
            "path": "/customers/view"
          },
          {
            "linkText": "Edit",
            "path": "/customers/edit"
          }
        ]);
        setSelectedCustomer(data);
      });
    }
    catch(e) {
      toast('Something went wrong, please try again later.')
    }
  };
  useEffect(() => {
    setCurrentBreadcrumb([
      {
        "linkText": "All Customers",
        "path": "/customers"
      },
      {
        "linkText": "Edit",
        "path": "/customers/edit"
      }
    ]);
    fetchCustomerData();
  }, []);

  return (
    <div className="edit-customer">
      <AddCustomer isEditCustomer={true} selectedCustomer={selectedCustomer}/>
    </div>
  )
};

export default EditCustomer;